"use strict"

import "./src/scss/style.scss"
import "./src/css/flickity.css"
import "magnific-popup/src/css/main.scss"

import $ from "jquery";
import Flickity from "flickity";
import "magnific-popup";
import "jquery-touchswipe";


if (module.hot) {
    module.hot.accept(function () {
        window.location.reload();
    });
}

const Wrapper = document.querySelector(".screens");
const Inner = Wrapper.querySelector(".screens__inner");
const Screens = document.querySelectorAll(".screen");
const Aside = document.querySelector(".aside");
const AsideElements = Aside.querySelectorAll("a");

(() => {
    let throttle = function(type, name, obj) {
        obj = obj || window;
        let running = false;
        let func = function() {
            if (running) { return; }
            running = true;
             requestAnimationFrame(function() {
                obj.dispatchEvent(new CustomEvent(name));
                running = false;
            });
        };
        obj.addEventListener(type, func);
    };

    /* init - you can init any event */
    throttle("resize", "optimizedResize");
})();

let currentScreen = 0;

const addClassWithDelay = (index, Screens) => setTimeout(() => {
    Screens[index - 1].classList.add("active");
}, 500)

const moveTo = (index, refresh = false) => {
    currentScreen = index;
    Inner.style.marginTop = `-${Wrapper.clientHeight * (index - 1)}px`;

    if(refresh) return;
    Screens.forEach(Screen => Screen.classList.contains("active") ? Screen.classList.remove("active") : null);
    AsideElements.forEach(El => El.classList.contains("active") ? El.classList.remove("active") : null);
    AsideElements[index - 1].classList.add("active");
    AsideElements[index - 1].scrollIntoView({ inline: "center", behavior: "smooth" });
    addClassWithDelay(index, Screens)
    location.hash = index;
}

// handle event
window.addEventListener("optimizedResize", function() {
    moveTo(currentScreen, true)
});

const windowOptions = Window => {
    if(!Window) return;
    // Window Actions
    const Next = Window.querySelector(".window__next");
    if(Next) {
        console.log(Next)
        Next.onclick = e => {
            e.preventDefault();
            moveTo(2);
        }
        return
    }
    const ReadMore = Window.querySelector(".window__read-more");
    const Close = Window.querySelector(".window__close");
    const CarouselBlock = Window.querySelector(".carousel__inner");
    const NextSlide = Window.querySelector(".carousel__next");
    const PrevSlide = Window.querySelector(".carousel__prev");

    let windowCarousel = null;

    ReadMore.onclick = e => {
        e.preventDefault();

        if (!windowCarousel) {
            windowCarousel = new Flickity(CarouselBlock, {
                freeScroll: true,
                contain: true,
                prevNextButtons: false,
                pageDots: false
            })
        }

        Window.classList.add("active");

        $(CarouselBlock).find(".flickity-slider").magnificPopup({
            delegate: 'a',
            type: 'image',
            gallery: {
                enabled: true,
                preload: [0,2]
            },
            callbacks: {
                open: function() {
                    // disable screen scrolling on open gallery
                    $(".mfp-wrap").on("wheel", e => e.stopPropagation());
                },
            }
        });
    }

    Close.onclick = e => {
        e.preventDefault();
        Window.classList.remove("active");
    }

    PrevSlide.onclick = () => {
        if (!windowCarousel) return
        windowCarousel.previous();
    }

    NextSlide.onclick = () => {
        if (!windowCarousel) return
        windowCarousel.next();
    }


}

Screens.forEach(Screen => {
    const window = Screen.querySelector(".window");
    if (window) windowOptions(Screen.querySelector(".window"));
});


const asideElements = (El, index) => {
    El.onclick = e => {
        e.preventDefault();
        if (El.classList.contains("active")) return false
        moveTo(index);
    }
}

let elCounter = 0;
AsideElements.forEach(El => {
    elCounter++;
    asideElements(El, elCounter)
});

// scrolling screens by mouse wheel

let isWheelDisabled = false;

const disableWheel = () => {
   isWheelDisabled = true;
   setTimeout(() => {
       isWheelDisabled = false;
   }, 500);
};

const scrollScreens = (e) => {
    if (isWheelDisabled) return;

    if (e.deltaY < 0) {
        const prevScreen = currentScreen - 1;
        if (prevScreen > 0) {
            disableWheel();
            moveTo(prevScreen);
        }
    }
    if (e.deltaY > 0) {
        const nextScreen = currentScreen + 1;
        if (nextScreen <= 9) {
            disableWheel();
            moveTo(nextScreen);
        }
    }
};

window.addEventListener("wheel", scrollScreens);

// screens swiping
$(".screens").swipe({
    swipe: (e, direction) => {
        const disableOnElements = ["window__inner", "window__full-description"];
        const isDisabled = disableOnElements.some(className => {
            const targetClasses = e.target.classList;
            const targetParentClasses = e.target.parentNode.classList;
            return targetClasses.contains(className) || targetParentClasses.contains(className);
        });
        if (isDisabled) return;

        if (["up", "down"].includes(direction)) {
            scrollScreens({ deltaY: direction === "up" ? 1 : -1 })
        }
    },
    allowPageScroll: "auto"
})

// dont scroll screens when mouse in window__inner
$(".window__inner").on("wheel", e => {
    e.stopPropagation();
})

// set --vh variable for mobiles
document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
window.addEventListener('resize', () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
});

if (location.hash && +location.hash.slice(1) <= AsideElements.length) {
    moveTo(location.hash.slice(1));
} else {
    moveTo(1);
}